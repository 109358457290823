import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthenticationService } from "../services/authentication.service";
import { Router } from "@angular/router"
import { catchError } from "rxjs/operators"
import {UnauthorizedAlpacaCode} from "../consts/unauthorizedAlpacaCode";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	constructor(
		private _authenticationService: AuthenticationService,
		private _router: Router,
		private _toastrService: ToastrService
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const currentUser = this._authenticationService.currentUserValue;
		const isAuthorized: boolean = !!currentUser?.tokens?.access
		const accessToken: string = currentUser?.tokens?.access

		if (isAuthorized) {
			if (req.url.startsWith(environment.apiUrl) || req.url.startsWith(environment.clientTradeUrl)) {
				req = this.addApiAuthorization(req, accessToken)
			} else {
				console.error('Неизвестный URL', req.url)
			}
		}

		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => this._errorHandler(error))
		)
	}

	private addApiAuthorization(req: HttpRequest<any>, token: string): HttpRequest<any> {
		return req.clone({
			setHeaders: {
				Authorization: `Bearer ${ token }`
			}
		})
	}

	private _errorHandler(error: HttpErrorResponse): Observable<HttpEvent<any>> {
		if (error.status === 401) {
			if (error?.error?.code === UnauthorizedAlpacaCode) {
				this._toastrService.error(error?.error?.message);
				this._router.navigate(['/general/users']);
			} else {
				this._authenticationService.logout();
				this._router.navigate(['/auth/login']);
			}
		}
		return throwError(error)
	}

}
